<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true" >
        <el-form-item label="名称">
            <el-input v-model="query.name" placeholder="输入名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="类型">
            <el-select v-model="query.type" placeholder="选择合作商类型" clearable>
            <el-option label="合作商" value="cooperator"></el-option>
            <el-option label="物业" value="property"></el-option>
            <el-option label="商会" value="commerce"></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="状态">
            <el-select v-model="query.status" placeholder="选择" clearable>
            <el-option label="正常" :value="0"></el-option>
            <el-option label="冻结" :value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button  type="primary" @click="getList(1)">搜索</el-button>
        </el-form-item>
        <el-form-item>
            <el-button v-permission="'addCooperator'" type="primary" @click="add">新增</el-button>
        </el-form-item>
    </el-form>
   
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>{{ type[row.type] }}</div>
        <el-tag v-if="row.status == 0" type="success" size="small">正常</el-tag>
        <el-tag v-if="row.status == 2" type="danger" size="small">冻结</el-tag>
      </template>
      <template #time="{ row }">
        <div>
            开始：{{row.start_time}}
        </div>
        <div>
            结束：{{row.end_time}}
        </div>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="update(row)"
          >编辑</el-button
        >
         <el-button size="small" type="primary" @click="details(row)"
          >详情</el-button
        >
        <el-button v-if="row.status === 0" size="small" type="danger" @click="updateStatus(row,2)"
          >冻结</el-button
        >
         <el-button v-if="row.status === 2"  size="small" type="success" @click="updateStatus(row,0)"
          >解冻</el-button
        >

      </template>
    </auto-table>
    <AddorEdit @success="getList" ref="AddorEdit"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from "./components/AddorEdit.vue";
export default {
  components: {
    AddorEdit,
  },
  data() {
    return {
      nav: { firstNav: "合作商管理", secondNav: "主理人列表" },
      DataList: [],
     query:{
       name:'',
      type:'',
     },
      type: {
        cooperator: "合作商",
        property: "物业",
        commerce:'商会',
        manageingPartner:'主理人'
      },
      Option: [
        { name: "名称", value: "title" },
        { name: "联系人", value: "name" },
        { name: "联系电话", value: "phone" },
        { name: "类型", value: "type", type: "custom" },
         { name: "主理时间", value: "time", type: "custom" ,width:"200"},
        { name: "城市", value: "city" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    details(row){
      this.$router.push({name:'mpDetail',query:{id:row.id}});
    },
    add() {
      this.$refs.AddorEdit.open();
    },
    update(row) {
      this.$refs.AddorEdit.open(row);
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
     updateStatus(row,status){
      this.$axios({
        url: "/user/cooperator/update",
        data: {
          id:row.id,
          status
        },
        method:"post"
      }).then((res) => {
       this.getList(1)
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cooperator/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          title:this.query.name || null,
           status:this.query.status,
          types:'manageingPartner',
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>